<script>
import {request_comments_grid_sets} from "../../../jsons/gridsets";
import MessageForm from "./MessageForm.vue";
import {
  DxDataGrid,
  DxScrolling,
  DxStateStoring,
//        DxPager,
//        DxPaging,
}  from 'devextreme-vue/data-grid';
import {bus} from "@/router/bus";
export default {
  name: "DclRequestComments.vue",
  components: {
    DxDataGrid,
    DxScrolling,
    DxStateStoring,
    MessageForm,
//            DxPager,
//            DxPaging,
  },
  props: {
    comments: {
      type: Array
    },
    ord_id: {
      type: Number, String
    },
    commentType: {
      type: String,
      required: false,
      default: () => "client",
      validator: (value) => ["client", "dcl"].includes(value),
    },
  },
  data() {
    return {
      columns: request_comments_grid_sets,
      showFilterRow: false,
      showHeaderFilter: true,
      autoExpandAll: true,
      currentMode: 'widget',
      dataGridRefName: 'dataGrid',
      nodatatext: "Комментарии отсутствуют",
      rowAlternationEnabled: true,
      showCommentText: false,
      showMessageForm: false,
      document: {},
      is_new: true,
    }
  },
  watch: {
    comments: function () {
      // console.log(this.comments)
    },
    deep: true
  },
  methods: {
    onrowdblclick(data) {
      this.is_new = false
      this.document = data.data
      this.showCommentText = true
      this.setVizited()
    },
    
    createnewcomment(){
      this.is_new = true
      this.document = []
      this.showMessageForm = true
    },
  
    setVizited() {
      this.$postapi(this.$address + this.$setters.setCommentVizited.uri, {
        method: this.$setters.setCommentVizited.name,
        id: this.document.id
      }).then(data => {
        if (data.error != 0) {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        } else {
          bus.$emit("recalccommentscount")
        }
      });
    },
    
    getcommentsids(){
      let ids = ''
      for (let i = 0; i < this.comments.length; i++){
        if (i == 0)
          ids = this.comments[i].id
        else
          ids = ids + ',' + this.comments[i].id
      }
      return ids
    },

    setallconnentsvizited() {
      this.$postapi(this.$address + this.$setters.setMultiCommentVizited.uri, {
        method: this.$setters.setMultiCommentVizited.name,
        ids: this.getcommentsids()
      }).then(data => {
        if (data.error != 0) {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
        bus.$emit("recalccommentscount")
      });
    }
  }
}
</script>

<template>
  <div>
    <div class="request-title">
      <div class="comments-action">
        <div class="btn-left">
          <!--v-btn
            class="mx-2 btm-brdr-right btn-height"
            text
            dark
            small
            color="primary"
            @click="setallconnentsvizited"
          >
            Пометить все как прочитанные
            <v-icon dark>
              mdi-message-processing
            </v-icon>
          </v-btn-->

        </div>
        <div class="btn-right">

          <!--v-btn
            class="mx-2 btm-brdr-right btn-height"
            text
            dark
            small
            color="primary"
            @click="createnewcomment"
          >
            Написать сообщение
            <v-icon dark>
              mdi-message-text-outline
            </v-icon>
          </v-btn-->

        </div>
      </div>
    </div>

    <DxDataGrid
      width="100%"
      id="mt-order-history"
      :columns="columns"
      :ref="dataGridRefName"
      :selection="{ mode: 'single' }"
      :data-source="comments"
      :allow-column-reordering="true"
      :show-borders="true"
      :allow-column-resizing="true"
      :column-resizing-mode="currentMode"
      :column-min-width="50"
      :column-auto-width="true"
      :hoverStateEnabled="true"
      :word-wrap-enabled="true"
      :row-alternation-enabled="rowAlternationEnabled"
      :no-data-text="nodatatext"
      @row-dbl-click="onrowdblclick"
      @row-click="onrowdblclick"
    >

      <v-dialog
        class="mt-editor-form"
        v-model="showMessageForm"
        persistent
        transition="dialog-bottom-transition"
        width="60%" height="60%"
      >

        <MessageForm
          :commentType="commentType"
          :document ="document"
          :is_new="is_new"
          :ord_id="this.ord_id"
          @close="showMessageForm = false, $emit('updateComments')"
        />
      </v-dialog>
  
      <v-dialog
        class="mt-editor-form"
        v-model="showCommentText"
        persistent
        @click:outside="showCommentText = false"
        transition="dialog-bottom-transition"
        width="45%" height="60%"
      >
        <v-card>
         <div class="comment-dialog-text">
           {{document.description}}
         </div>
    
          <v-divider></v-divider>
    
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="var(--main-bg-color)"
              text
              @click="showCommentText = false"
            >
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
        
      </v-dialog>
      
      <DxScrolling mode="infinite"/>
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="dcl-request-comments"
      />
    </DxDataGrid>
  </div>
</template>

<style scoped>
.mt-editor-form{
  background-color: #fff;
  overflow: hidden !important;
}
.v-dialog{
  background-color: #ffffff;
  width: 60% !important;
}

.comment-dialog-text {
  font-size: 18px;
  padding: 40px 20px;
}

.btn-right{
  text-align: right;
  margin: 0.4%;
  float: right;
}
.btn-left{
  text-align: left;
  margin: 0.4%;
  float: left;
}
.btn-height{
  min-height: 2.5vw;
}
#mt-order-history{
  height: calc(100vh - 250px);
}
.comments-action{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
</style>
