<template>
  <div class="mt-order-file-card" :title="file.titlename" @click="cardOn()">
    <img class="mt-order-file-img" :src="getIcon(file.titlename)" />
    <span>{{ file.titlename }}</span>
  </div>
</template>

<script>
export default {
  name: "FilesCard",
  props: {
    file: {
      type: Object
    }
  },

  methods: {
    getIcon(filename) {
      switch (filename.match("\\.[a-z,A-Z]*$")[0]) {
        case ".xls":
        case ".xlsx":
        case ".json":
          return "file-types\\mdi-file-excel.png";
        case ".doc":
        case ".dcox":
          return "file-types\\mdi-file-word.png";
        case ".pdf":
          return "file-types\\mdi-file-pdf.png";
        case ".jpeg":
        case ".jpg":
        case ".png":
        case ".bmp":
        case ".tif":
        case ".tiff":
          return "file-types\\mdi-file-image.png";
        default:
          return "file-types\\mdi-file.png";
      }
    },
    
    cardOn(){
      this.$postapi(this.$address + this.$getters.getFileByPath.uri, {
        method: this.$getters.getFileByPath.name,
        path: this.file.filename
      }).then(data => {
        this.$savefile(data);
      });
    }
  }
}
</script>

<style scoped>
.mt-order-file-card {
  display: grid;
  font-size: 0.7vw;
  min-height: 6.25vw;
  cursor: pointer;
}
.mt-order-file-card span{
  word-wrap: break-word;
  margin: auto;
  width: 6.25vw;
}
.mt-order-file-img{
  background: #f5f8fd;
  width: 3.125vw;
  height: 3.125vw;
  margin: auto;
  border-radius: 50%;
}
</style>
