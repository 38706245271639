<script>
import DclRequestEd22Info from "./ed22-form/DclRequestEd22Info.vue";
import DclRequestAltaInfo from "./ed22-form/DclRequestAltaInfo.vue";

export default {
  name: "DclRequestEd22.vue",
  components:{
    DclRequestEd22Info,
    DclRequestAltaInfo,
  },
  props: {
    order: {
      type: Object
    }
  },
  data() {
    return {
      dataSource: [],
      providerinfo: [],
      region: 107,
      stype: 'LikeMatch',
      provider_id: '',
      ed22info: {},
      port_id : 0,
      cont_num: '',
      noDataError: false,
    }
  },
  
  watch: {
    order: function (value) {
      this.noDataError = false
      if (value.cont_num != null) {
        let cont_nums = value.cont_num.split(",")
        this.cont_num = cont_nums[0]
      } else {
        this.cont_num = null
      }
      this.ed22info = {}
      this.port_id = value.port_id
    },
  },
  
  mounted() {
    if (this.order.cont_num != null) {
      let cont_nums = this.order.cont_num.split(",")
      this.cont_num = cont_nums[0]
    } else {
      this.cont_num = null
    }
    this.ed22info = {}
    this.port_id = this.order.port_id
  },
  
  methods: {
    getInfo() {
      this.noDataError = false
      if (this.port_id == 102){
        this.getaltainfo(this.cont_num)
      }
      else {
        this.geted22info(this.cont_num)
      }
    },
    
    geted22info(cont_num) {
      this.$postapi(this.$address + this.$getters.getInfoByContainer.uri, {
        method: this.$getters.getInfoByContainer.name,
        pattern: cont_num,
        region: this.region,
        stype: this.stype
      }).then(data => {
        if (typeof(data[0]) == "undefined") {
          this.noDataError = true
        } else {
          this.ed22info = data[0]
          this.provider_id = this.dataSource.WhInfoProvider
        }
      });
    },
    
    getaltainfo(cont_num){
      this.$postapi(this.$address + this.$getters.getAltaInfo.uri, {
        method: this.$getters.getAltaInfo.name,
        pattern: cont_num,
        region: this.region,
        stype: this.stype
      }).then(data => {
        console.log(typeof(data))
        this.ed22info = data
      });
    },
  },
}
</script>

<template>
  <div>
    <v-btn
      outlined
      class="text-left mt-3"
      color="var(--bg-dark)"
      @click="getInfo"
    >
      Получить данные
    </v-btn>
    
    <div class="mt-2" v-if="noDataError === true">Данные отсуствуют</div>
    
    <DclRequestEd22Info
      v-if="port_id != 102 && Object.keys(ed22info).length > 0"
      :ed22info="ed22info"
      :cont_num="cont_num"
    />
    
    <DclRequestAltaInfo
      v-if="port_id == 102 && Object.keys(ed22info).length > 0"
      :ed22info="ed22info"
      :cont_num="cont_num"
    />
  </div>
</template>

<style scoped>
    /*p{*/
    /*    margin-left: 5px;*/
    /*}*/
    .p-10{
        margin-left: 15px;
        font-size: 11pt;
    }
    .p-block{
        border-bottom: 1px solid #5ebdf3;
        background-color: #e5f0f7;
    }
    .p-red{
        background-color: #FFBCBC;
    }
    .p-green{
        background-color: #B3E283;
    }
    .p-yellow{
        background-color: #FFED99;
    }
    .p-light-green{
        background-color: #CEE5D0;
    }
</style>
