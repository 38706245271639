export const request_form_spec =
    {
        delivery_term_element: {
            id: 0,
            caption: "Условия поставки",
            name: "delivery_term_id",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: false,
            reference: 'ClientVDRefDeliveryTerms',
        },
        procedures_element: {
            id: 0,
            caption: "Процедуры",
            name: "procedure",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: true,
            reference: 'ClientVDRefProcedures',
        },
        options_element: {
            id: 0,
            caption: "Особенности",
            name: "options",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: true,
            reference: 'ClientVDRefOptions',
        },
        files_goods: {
            id: 0,
            caption: "Инвойс (спецификация)",
            name: "goods",
            multiple: true,
        },
        files_commerce: {
            id: 0,
            caption: "Коммерческие документы",
            name: "commerce",
            multiple: true,
        },
        files_transport: {
            id: 0,
            caption: "Транспортные документы",
            name: "transport",
            multiple: true,
        },
        files_access: {
            id: 0,
            caption: "Разрешительные документы",
            name: "access",
            multiple: true,
        },
        files_technic: {
            id: 0,
            caption: "Тех. документация",
            name: "technic",
            multiple: true,
        },
        files_price: {
            id: 0,
            caption: "Стоимостные документы",
            name: "price",
            multiple: true,
        },
        files_other: {
            id: 0,
            caption: "Прочие документы",
            name: "other",
            multiple: true,
        },
        contract_date_element: {
            caption: "Дата контракта",
            name: "contract_date",
        },
        sea_end_date_element: {
            caption: "Дата прибытия",
            name: "sea_end_date",
        },
        contract_number_element: {
            caption: "Номер контракта",
            name: "contract_number",
        },
        comment_element: {
            caption: "Комментарий",
            name: "comment",
            lines: 3,
        },
        ports_element: {
            id: 0,
            caption: "Порт прибытия",
            name: "customs_point_id",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: false,
            reference: 'ClientVDRefPoint',
        },
        customs_pay_element: {
            caption: "Оплата ТП",
            name: "customs_pay",
        },
        customs_pay_date_element: {
            caption: "Дата оплата ТП",
            name: "customs_payment_date",
        },
    }