<script>
import CVTBDateInput from "../../../common/CVTBDateInput.vue";
import MainInput from "../../../common/MainInput.vue";
import {bus} from "../../../../router/bus";
import AppLoader from '@/components/ui/AppLoader.vue';

export default {
  name: "FinanceDocumentRequestForm",
  
  components: {
    CVTBDateInput,
    MainInput,
    AppLoader
  },
  
  data() {
    return {
      documentTypes: [],
      profileData: [],
      
      contractIndex: 0,
  
      consignesDataList: {
        id: "id",
        name: "shortname",
        caption: "Юр. лицо",
        listname: "consignes_list",
        listfield: "shortname",
        rules: [
          v => !!v || 'Наименование обязательно',
          value => {
            const pattern = /[`']/;
            return !pattern.test(value) || "Присутствуют запрещённые символы"
          },
        ]
      },
      
      elements: {
        date_from: {
          caption: "Дата начала анализа",
          name: "date_from",
          disabled: false,
          type: "date"
        },
    
        date_to: {
          caption: "Дата окончания анализа ",
          name: "date_to",
          disabled: false,
          type: "date"
        },
      },
      
      formdata: {
        req_type: {},
        date_from: '',
        date_to: '',
        cont_number: '',
        consignee_client: null,
        consignee_tramis: null,
      },

      showLoader: false,
    }
  },
  
  mounted() {
    this.getUser()
    this.getReferenceData('vdx_ref_client_request_type')
    // this.getDateFrom()
  },
  
  methods: {
    getReferenceData(referenceName) {
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: referenceName
      }).then(data => {
        if (data.error == 0) {
          this.documentTypes = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
        }
      });
    },

    getUser() {
      this.showLoader = true
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0){
          this.profileData = JSON.parse(data.message)
          this.showLoader = false
        }
      });
    },
    
    sendRequest() {
      if (this.formdata.req_type == 1388) {
        this.setCompleteDocRequest()
      } else {
        this.addClientRequest()
      }
    },
    
    addClientRequest() {
      this.$postapi(this.$address + this.$getters.addClientRequest.uri, {
        method: this.$getters.addClientRequest.name,
        token: this.$_getsetting('token'),
        date_from: this.formdata.date_from,
        date_to: this.formdata.date_to,
        req_type: this.formdata.req_type,
        objects: this.formdata.cont_number.replaceAll(' ', '').split(','),
        // objects: this.formdata.req_type == 1388 ?
          // this.formdata.consignee_tramis : this.formdata.cont_number.replaceAll(' ', '').split(',')
      }).then(data => {
        if (data.error == 0){
          this.$emit('close')
          bus.$emit("show_notify", {
            color: "success",
            notifytext: 'Ваш запрос создан!'
          });
        }
      });
    },
    
    setCompleteDocRequest() {
      this.$postapi(this.$address + this.$getters.addClientRequest.uri, {
        method: this.$getters.setCompleteDocRequest.name,
        token: this.$_getsetting('token'),
        req_type: this.formdata.req_type,
        date_from: this.formdata.date_from,
        date_to: this.formdata.date_to,
        objects: this.formdata.consignee_tramis,
      }).then(data => {
        if (data.error == 0){
          bus.$emit("show_notify", {
            color: "success",
            notifytext: 'Ваш запрос создан!'
          });
          setTimeout(() => {
            this.$emit('close')
          }, 1800);
        }
      });
    },
  
    getDateFrom() {
      var d = new Date();
      var from = d.setMonth(d.getMonth(), 0);
      from = d.setMonth(d.getMonth(1), 1);
      console.log(from)
    },
    
    changeIndex() {
      this.contractIndex = this.profileData.payers_data.findIndex(el => el.id === this.formdata.consignee_client);
    }
  }
}
</script>

<template>
  
  <div class="request-form elevation-3">

    <AppLoader class="request-form__loader" :size="100" v-if="showLoader"/>

    <v-form 
      v-else-if="documentTypes.length > 0"
      @submit.prevent="sendRequest()"
    >
      <div class="request-form__title">
        Заказать документ
      </div>

      <v-autocomplete
        v-if="documentTypes.length > 0"
        v-model="formdata.req_type"
        :items="documentTypes"
        clearable
        outlined
        item-text="obj_name"
        item-value="id"
        label="Тип документа"
        item-color="var(--main-bg-color)"
        color="var(--main-orange)"
      />

      <v-autocomplete
        v-if="profileData && profileData.payers_data"
        v-model="formdata.consignee_client"
        :items="profileData.payers_data"
        clearable
        outlined
        item-text="shortname"
        item-value="id"
        label="Юр. лицо"
        item-color="var(--main-bg-color)"
        color="var(--main-orange)"
        @change="changeIndex"
      />

      <div v-if="profileData && profileData.payers_data && profileData.payers_data[contractIndex] && profileData.payers_data[contractIndex].contract_data">
        <v-autocomplete
          v-model="formdata.consignee_tramis"
          :items="profileData.payers_data[contractIndex].contract_data"
          clearable
          outlined
          multiple
          item-text="shortname"
          item-value="id"
          label="Юр. лицо Трамис"
          item-color="var(--main-bg-color)"
          color="var(--main-orange)"
        />
      </div>
      
      <CVTBDateInput
        v-if="formdata.req_type == 1388"
        @selected="formdata.date_from = $event.value"
        @input="formdata.date_from = $event"
        :element="elements.date_from"
        :val="formdata.date_from"
      />
    
      <CVTBDateInput
        v-if="formdata.req_type == 1388"
        @selected="formdata.date_to = $event.value"
        @input="formdata.date_to = $event"
        :element="elements.date_to"
        :val="formdata.date_to"
      />
    
      <MainInput
        v-if="formdata.req_type != 1388"
        type="text"
        label="Номер контейнера"
        outlined
        color="var(--main-orange)"
        hint="Введите один номер или несколько через запятую"
        v-model.trim="formdata.cont_number"
      />
      
      <v-btn
        color="var(--main-bg-color)"
        text
        type="submit"
      >
        Отправить запрос
      </v-btn>
    </v-form>
  </div>
</template>

<style scoped lang="scss">
.request-form {
  height: auto;
  background-color: #fff;
  width: 100%;
  z-index: 3333 !important;
  position: absolute;
  padding: 20px 40px 30px;
  //border: 1px solid #a9a9a9;
  border-radius: 6px;
  
  &__title {
    font-size: 22px;
    font-weight: 700;
    margin: 10px 0 20px;
  }

  &:has(.request-form__loader){
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
