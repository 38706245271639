<script>
import {requested_documents_grid} from "../../../jsons/finances-tab";
import {bus} from "../../../../router/bus";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import {
  DxDataGrid,
  DxScrolling,
  DxColumnFixing,
  DxStateStoring,
  DxSummary,
  // DxTotalItem,
  DxHeaderFilter,
  DxColumnChooser,
  DxExport,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";

export default {
  name: "FinanceDocumentList",
  
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxStateStoring,
    DxLoadPanel,
    DxSummary,
    // DxTotalItem,
    DxHeaderFilter,
    DxColumnChooser,
    DxPager,
    DxPaging,
    DxExport,
  },
  
  data() {
    return {
      requestList: [],
      gridNames: requested_documents_grid,
      dataGridRefName: "CVDocumentListDataGrid",
      rowAlternationEnabled: true,
      showHeaderFilter: true,
      filterValue: "",
      currentMode: "widget",
      sync: true,
      loadingVisible: false,
    }
  },
  
  mounted() {
    this.getClientRequestsList()
  },
  
  methods: {
    getClientRequestsList() {
      this.$postapi(this.$address + this.$getters.getClientRequestsList.uri, {
        method: this.$getters.getClientRequestsList.name,
        client_id: this.$_getsetting('client_id'),
        // client_id: 1410,
      }).then(data => {
        if (data.error == 0) {
          this.requestList = JSON.parse(data.message.toString())
          this.parseNumberData()
          // console.log(JSON.parse(this.requestList[0].objects))
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    parseNumberData() {
      for (let i = 0; i < this.requestList.length; i++) {
        this.requestList[i].cont_numbers = JSON.parse(this.requestList[i].objects).map(obj => obj.cont_num);
        this.requestList[i].ord_numbers = JSON.parse(this.requestList[i].objects).map(obj => obj.ord_num);
      }
    }
  }
}
</script>

<template>
  <div class="request-form">
    <div class="invoice__list">
      <DxLoadPanel
        style="z-index: 3020 !important;"
        :visible.sync="loadingVisible"
        :show-indicator="true"
        :show-pane="true"
        :shading="true"
        :close-on-outside-click="false"
      />
    
      <DxDataGrid
        width="100%"
        key-expr="id"
        :hoverStateEnabled="true"
        id="mt-document-list"
        :columns="gridNames"
        :ref="dataGridRefName"
        :selection="{ mode: 'single' }"
        :data-source="requestList"
        :word-wrap-enabled="true"
        :allow-column-reordering="true"
        :show-borders="true"
        :focused-row-enabled="true"
        :allow-column-resizing="true"
        :column-resizing-mode="currentMode"
        :row-max-height="10"
        :column-auto-width="true"
        :filter-value="filterValue"
        :filter-sync-enabled="sync"
        :row-alternation-enabled="rowAlternationEnabled"
      >
        <DxScrolling mode="standard"/>
      
        <DxPaging :page-size="1000"/>
      
        <DxPager
          :visible="false"
        />
      
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="mt-document-list"
        />
      
        <DxHeaderFilter
          :visible="showHeaderFilter"
        />
      
        <DxExport
          :enabled="true"
          :allow-export-selected-data="true"
          class="mt-4"
          file-name="mt-document-list"
        />
      
        <DxColumnFixing :enabled="true"/>
      
        <DxColumnChooser class="mt-2" :enabled="true"/>
      
        <DxColumnFixing :enabled="true"/>
        <DxSummary>
<!--          <DxTotalItem-->
<!--            :customize-text="ord_id_text"-->
<!--            column="id"-->
<!--            summary-type="count"-->
<!--          />-->
<!--        -->
      
        </DxSummary>
      </DxDataGrid>
    </div>
  </div>
</template>

<style scoped lang="scss">
.document-list {
  height: 85vh;
  display: flex;
  background-color: #fff;
  overflow-x: auto !important;
  width: 100%;
}

</style>
