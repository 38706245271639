<template>
  <div class="success-registration">
    <div class="success-registration__wrapper">
      <h2 class="success-registration__title">Добро пожаловать в Vedexx!</h2>
      <p class="success-registration__description">Вы зарегистрировались в Vedexx.com.</p>
      <p class="success-registration__description">Ваши логин и пароль для входа отправлены на электронную почту.</p>
      <img class="success-registration__img" src="@/assets/img/success-registration-img.webp"
        alt="Добро пожаловать в Vedexx">
    </div>
  </div>
</template>

<script>

export default {
  name: "RegistrationDone",
}
</script>

<style scoped lang="scss">
.success-registration {
  position: relative;
  width: 100%;
  height: 100%;

  margin-top: 100px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    max-width: 1030px;
    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    @media (max-width: $tablet-width){
      max-width: 530px;
    }

    @media (max-width: 400px){
      max-width: 300px;
    }
  }

  &__title {
    @include font-headline-0;
    color: var(--grey-70);
    margin-bottom: 32px;

    @media (max-width: $tablet-width){
      @include font-headline-1;
    }

    @media (max-width: $mobile-width){
      @include font-body-bold-1;
    }
  }

  &__description {
    @include font-body-1;
    color: var(--grey-70);

    @media (max-width: $tablet-width){
      @include font-body-2;
    }

    @media (max-width: $mobile-width){
      @include font-body-3;

      & ~ p{
        margin-top: 20px;
      }
    }
  }

  &__img {
    margin-top: 22px;
    max-width: 380px;
    width: 100%;
  }
}
</style>
