<script>
import RateCardShort from "../rate-card/RateCardShort.vue";

export default {
  name: "LetterInfoCard",
  components: {
    RateCardShort
  },
  
  props: {
    offer: {
      type: [Array, Object]
    },
  },
  
  data() {
    return {
      showOptions: false,
    }
  },
}
</script>

<template>
  <div class="mail-card">
    <div class="mail-card__title" @click="showOptions = true">{{offer.data.route_name}}</div>
    <v-btn
      dark
      class="mail-card__btn"
      color="var(--main-orange)"
      depressed
      @click="$emit('declineMail', offer.id)"
    > Отменить
    </v-btn>
    
    <v-dialog
      v-model="showOptions"
      persistent
      width="60%"
      transition="dialog-bottom-transition"
      @click:outside="showOptions = false"
    >
      <div
        class="mail-card__rates"
      >
        <RateCardShort
          :orderButton="false"
          v-for="(item, index) in offer.data.rates"
          :key="index"
          class="rate-list__main-block__card-container-card"
          :rate="item"
        />
      </div>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.mail-card {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  width: 100%;
  background: #FFFFFF;
  border: 1.64319px solid #E2E2E2;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0vw 0.15625vw 0.05208vw -0.1041666vw rgba(96, 97, 99, 0.2),
  0vw 0.1041666vw 0.1041666vw 0vw rgba(96, 97, 99, 0.14),
  0vw 0.05208vw 5px 0vw rgba(96, 97, 99, 0.12);
  
  &__title {
    @include font-body-1;
    
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0.01em;
    color: #000000;
    
    &:hover {
      color: var(--main-bg-color);
    }
    
    &:focus, &:active, &:visited {
      color: var(--bg-dark);
    }
  }
  
  &__btn {
    text-transform: none;

    @include font-description-semibold-0;
  }
  
  &__rates {
    background: #FFFFFF;
    height: 100%;
    padding: 10px;
  }
}
</style>
