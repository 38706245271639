<script>
import {bus} from "../../../../router/bus";
import { finance_grid_set } from "../../../jsons/finances-tab";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import {
  DxDataGrid,
  DxScrolling,
  DxColumnFixing,
  DxStateStoring,
  DxSummary,
  DxTotalItem,
  DxHeaderFilter,
  DxColumnChooser,
  DxExport,
  DxPager,
  DxPaging,
  // DxMasterDetail,
} from "devextreme-vue/data-grid";

export default {
  name: "FinanceInvoicesList",
  
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxStateStoring,
    DxLoadPanel,
    DxSummary,
    DxTotalItem,
    DxHeaderFilter,
    DxColumnChooser,
    DxPager,
    DxPaging,
    DxExport,
    // DxMasterDetail,
  },
  
  data() {
    return {
      invoicesData: [],
      gridNames: finance_grid_set,
      dataGridRefName: "CVFinanceListDataGrid",
      rowAlternationEnabled: true,
      showHeaderFilter: true,
      filterValue: "",
      currentMode: "widget",
      sync: true,
      loadingVisible: false,
    }
  },
  
  mounted() {
    this.getNotPayedInvoices()
  },
  
  methods: {
  
    ord_id_text(data){
      return data.value.toLocaleString()
    },
    
    getNotPayedInvoices() {
      this.$postapi(this.$address + this.$getters.getNotPayedInvoices.uri, {
        method: this.$getters.getNotPayedInvoices.name,
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0) {
          this.invoicesData = JSON.parse(data.message)
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  }
}
</script>

<template>
  <div class="invoice">
    <div class="invoice__list">
      <DxLoadPanel
        style="z-index: 3020 !important;"
        :visible.sync="loadingVisible"
        :show-indicator="true"
        :show-pane="true"
        :shading="true"
        :close-on-outside-click="false"
      />
    
      <DxDataGrid
        width="100%"
        key-expr="ord_id"
        :hoverStateEnabled="true"
        id="mt-request-list2"
        :columns="gridNames"
        :ref="dataGridRefName"
        :selection="{ mode: 'single' }"
        :data-source="invoicesData"
        :word-wrap-enabled="true"
        :allow-column-reordering="true"
        :show-borders="true"
        :focused-row-enabled="true"
        :allow-column-resizing="true"
        :column-resizing-mode="currentMode"
        :row-max-height="10"
        :column-auto-width="true"
        :filter-value="filterValue"
        :filter-sync-enabled="sync"
        :row-alternation-enabled="rowAlternationEnabled"
      >
        <DxScrolling mode="standard"/>
      
        <DxPaging :page-size="1000"/>
      
        <DxPager
          :visible="false"
        />
      
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="mt-request-list2"
        />
      
        <DxHeaderFilter
          :visible="showHeaderFilter"
        />
      
        <DxExport
          :enabled="true"
          :allow-export-selected-data="true"
          class="mt-4"
          file-name="mt-request-list"
        />
        
        <DxColumnFixing :enabled="true"/>
      
        <DxColumnChooser class="mt-2" :enabled="true"/>
      
        <DxColumnFixing :enabled="true"/>
        <DxSummary>
          <DxTotalItem
            :customize-text="ord_id_text"
            column="ord_id"
            summary-type="count"
          />
        
          <DxTotalItem
            :customize-text="ord_id_text"
            column="amount_rur"
            summary-type="sum"
            value-format="#,##0.00"
          />
        
          <DxTotalItem
            :customize-text="ord_id_text"
            column="is_print_usd"
            summary-type="sum"
            value-format="#,##0.00"
          />
        </DxSummary>
  
<!--        <DxMasterDetail-->
<!--          :enabled="true"-->
<!--          template="detail"-->
<!--        />-->
<!--        <template #detail>-->
<!--          <div class="employeeInfo">-->
<!--            <img class="employeePhoto" :src="data.Picture" />-->
<!--            <p class="employeeNotes">{{ data.Notes }}</p>-->
<!--          </div>-->
<!--        </template>-->
      </DxDataGrid>
    </div>
  </div>
</template>

<style scoped lang="scss">
.invoice {
  height: 85vh;
  display: flex;
  background-color: #fff;
  //margin: 0;
  overflow-x: auto !important;
  width: 100%;
  
  &__list {
    width: 100%;
    margin: 10px 0 0 ;
    height: calc(100vh - 180px);
    overflow: visible;
  }
  
  &__panel {
    overflow-y: scroll;
    display: grid;
    
    grid-template-columns: repeat(2, 1fr);
    
    &-name {
      border: 1px solid #d0d0d0;
      border-radius: 4px;
    }
    
    &__table {
      border-collapse: collapse;
      table-layout: fixed;
      max-width: 600px;
      overflow-x: visible;
    }
  }
}

.dx-toolbar .dx-toolbar-items-container {
  height: 40px !important;
}

#mt-request-list2 {
  height: 100%;
  background: rebeccapurple;
  white-space: nowrap !important;
  overflow-x: auto !important;
}
</style>
