export const finance_grid_set = [
  {"dataField":"ord_id", "caption":"ID поставки", "dataType":"string"},
  {"dataField":"ord_num", "caption":"номер заказа", "dataType":"string"},
  {"dataField":"cont_num", "caption":"номер контейнера", "dataType":"string"},
  {"dataField":"invoice_num", "caption":"номер счета", "dataType":"string"},
  {"dataField":"invoice_date", "caption":"дата счета", "dataType":"string"},
  {"dataField":"description", "caption":"основание счета", "dataType":"string"},
  {"dataField":"invoice_dolg", "caption":"долг по счету, руб", "dataType":"string"},
  {"dataField":"unloading_date", "caption":"дата выгрузки КТК на складе", "dataType":"string"},
  {"dataField":"paym_id", "caption":"идентификатор счета в АРМ", "dataType":"string"},
  {"dataField":"clnt_id", "caption":"идентификатор клиента", "dataType":"string"},
  {"dataField":"payer", "caption":"наименование ЮЛ плательщика ДС", "dataType":"string"},
  {"dataField":"invr_name", "caption":"наименование ЮЛ получателя ДС", "dataType":"string"},
  {"dataField":"waybill_num", "caption":"коносамент", "dataType":"string"},
  {"dataField":"is_print_usd", "caption":"счет в валюте", "dataType":"string"},
  {"dataField":"is_ue_payment", "caption":"счет в У", "dataType":"string"},
  {"dataField":"amount_usd", "caption":"сумма счета USD", "dataType":"string"},
  {"dataField":"rate", "caption":"курс счета", "dataType":"string"},
  {"dataField":"rw_end_date", "caption":"дата прибытия на станцию", "dataType":"string"},
  {"dataField":"is_complete_doc", "caption":"сформированы закрывающие документы ", "dataType":"string"},
  {"dataField":"complete_doc_date", "caption":"дата отметки о закрывающих документах", "dataType":"string"},
  {"dataField":"amount_rur", "caption":"сумма счета RUR ", "dataType":"string"},
  {"dataField":"amount_eur", "caption":"сумма счета EUR", "dataType":"string"},
  {"dataField":"amount_cny", "caption":"сумма счета CNY", "dataType":"string"},
  {"dataField":"limit_date", "caption":"дата перехода в ПДЗ", "dataType":"string"},
  {"dataField":"is_pdz", "caption":"В ПДЗ", "dataType":"string"},
  {"dataField":"is_pdz_three", "caption":"в ПДЗ через 3 дня", "dataType":"string"},
  {"dataField":"amount", "caption":"эквивалент суммы в рублях", "dataType":"string"},
  {"dataField":"is_factoring", "caption":"на факторинге", "dataType":"string"},
  {"dataField":"cur_code", "caption":"валюта счета", "dataType":"string"},
]

export const requested_documents_grid = [
  {"dataField":"id", "caption":"ID заказа", "dataType":"string"},
  {"dataField":"crdate", "caption":"Дата заказа", "dataType":"string"},
  {"dataField":"clnt_name", "caption":"Клиент", "dataType":"string"},
  {"dataField":"is_edo", "caption":"ЭДО", "dataType":"string"},
  {"dataField":"state_name", "caption":"Статус", "dataType":"string"},
  {"dataField":"type_name", "caption":"Тип документа", "dataType":"string"},
  {"dataField":"cont_numbers", "caption":"Контейнеры"},
  // {"dataField":"ord_numbers", "caption":"Номер заказа"},
  ]
