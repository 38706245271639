<script>
import {bus} from "@/router/bus";

import CVTBTextInput from '../../common/CVTBTextInput';
import FtpUploader from "../../common/FtpUploader";

import { low_rating_form } from "../../jsons/low-rating-form";

export default {
  name: "LowRatingForm",
  components: {
    CVTBTextInput,
    FtpUploader
  },
  
  data: () => ({
    elements: low_rating_form,
    showloader: false,
    valid: false,
    
    progress: 1,
    showSendNotify: false,
    temp_id: Math.floor(Math.random() * 1e8),
    
    formdata: {
      tax_regime: '',
      revenue_four_months: '',
      revenue_first_january: '',
      tax_first_january: '',
      tax_four_months: '',
      avg_employees_four_months: '',
      number_of_employees: '',
      cost_of_fixed_assets: '',
      is_company_group: '',
      
      files: []
    }
  }),
  
  mounted() {
  },
  
  computed: {
    pagesCount()  {
      return this.formdata.tax_regime === 'ПСН' ? 1 : 2;
    },
    
    filteredFileInputs() {
      return Object.keys(this.elements)
        .filter(key => this.elements[key].type == 'file' && this.isVisibleFile(this.elements[key]) === true)
        .map(key => ({ key, ...this.elements[key] }));
    }
  },
  
  methods: {
    validate() {
      this.$refs.form.validate()
      if (this.valid == true) {
        this.sendForm()
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Заполните все обязательные разделы формы"
        });
      }
    },
    
    fileUploaded(value) {
        this.formdata.files.push(value)
    },
    
    goToProfile() {
      this.$router.push('/Profile')
    },
    
    isVisibleFile(element) {
      return element.tax_regime.includes(this.formdata.tax_regime);
    },
    
    sendForm() {
      this.$postapi(this.$address + this.$getters.setContragentRateInfo.uri, {
        method: this.$getters.setContragentRateInfo.name,
        token: this.$_getsetting('token'),
        company_id: this.$route.query.cid,
        tax_regime: this.formdata.tax_regime,
        revenue_four_months: this.formdata.revenue_four_months,
        revenue_first_january: this.formdata.revenue_first_january,
        tax_first_january: this.formdata.tax_first_january,
        tax_four_months: this.formdata.tax_four_months,
        avg_employees_four_months: this.formdata.avg_employees_four_months,
        number_of_employees: this.formdata.number_of_employees,
        cost_of_fixed_assets: this.formdata.cost_of_fixed_assets,
        is_company_group: this.formdata.is_company_group,
        temp_id: this.temp_id,
        files: this.formdata.files,
      }).then(data => {
        if (data.error == 0){
          this.showSendNotify = true
        }
      });
    }
  }
}
</script>

<template>
  <div class="low-rating">
    <v-form
      class="low-rating__form"
      ref="form"
      v-model="valid"
      @submit.prevent="validate"
    >
      <h2 class="text-center mb-8">Заполнение анкеты</h2>
      
      <span class="low-rating__form-step">Шаг {{progress}}/{{pagesCount}}</span>
      
      <div v-show="progress === 1">
        <div class="low-rating__form__container">
          <div
            v-for="(element, index) in elements"
            :key="index"
          >
            <CVTBTextInput
              v-if="element.type === 'text' || element.type === 'number'"
              @input="formdata[element.name] = $event"
              class="low-rating__form-input"
              :element="element"
              :outlined="false"
              :val="formdata[element.name]"
            />
            
            <v-select
              v-model="formdata[element.name]"
              v-if="element.type === 'select'"
              :items="element.items"
              :label="element.caption"
              :rules="element.rules"
              item-text="name"
              item-value="value"
              color="var(--main-orange)"
              item-color="var(--main-orange)"
              required
            ></v-select>
          </div>
        </div>
        
        <v-btn
          v-if="pagesCount === 2"
          class="low-rating__form-btn"
          color="var(--main-bg-color)"
          @click="formdata.tax_regime.length > 0 ? progress = 2 : $refs.form.validate()"
          type="button"
        >
          Далее
        </v-btn>
        
        <v-btn
          v-else
          class="low-rating__form-btn"
          color="var(--main-orange)"
          type="submit"
        >
          Сохранить
        </v-btn>
      </div>
      
      <div v-show="progress === 2">
        <div class="low-rating__form__container">
          <div
            v-for="(element, index) in filteredFileInputs"
            :key="index"
          >
            <FtpUploader
              :order_key="temp_id"
              :element="element"
              :rounded="true"
              :outlined="true"
              @fileUploaded="fileUploaded($event, element.name)"
            />
          </div>
        </div>
        
          <v-btn
            @click="progress = 1"
            class="low-rating__form-btn float-left mt-8"
            color="var(--main-bg-color)"
            type="button"
          >
            Назад
          </v-btn>
        
        <v-btn
          class="low-rating__form-btn mt-8"
          color="var(--main-orange)"
          type="submit"
        >
          Сохранить
        </v-btn>
      </div>
    </v-form>
    
    <v-dialog
      width="40%"
      v-model="showSendNotify"
    >
      <v-card
        class="mx-auto px-8 py-6 text--black"
        width="100%"
      >
        <v-card-title>
          Ваша анкета отправлена!
          <br/>
          Ожидайте ответа от нашего менеджера.
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            text
            color="var(--bg-dark)"
            @click="showSendNotify = false, goToProfile()"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.low-rating {
  align-content: center;
  align-items: center;
  
  &__form {
    margin: auto;
    display: block;
    width: 80%;
    padding: 32px;
    overflow-y: auto;
    background: white;
    overscroll-behavior-y: contain;
    position: relative;
    min-width: 500px;
    box-shadow: 0vw 0.15625vw 0.05208vw -0.1041666vw rgb(65, 64, 64),
    0vw 0.1041666vw 0.1041666vw 0vw rgba(96, 97, 99, 0.14),
    0vw 0.05208vw 5px 0vw rgba(96, 97, 99, 0.12);
    border-radius: 5px;
    //float: right;
    
    &-step {
      position: absolute;
      display: block;
      right: 40px;
      top: 80px;
      opacity: 0.7;
      font-weight: 500;
    }
    
    &__container {
      padding-top: 15px;
      font-size: 24px;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 5px;
      
      &-input {
      }
    }
    
    &-btn {
      text-transform: none;
      font-weight: 600;
      color: white;
      float: right;
    }
  }
}
</style>
