<template>
  <div>
    <v-list-item-content>
      <div class="mt-order-data-block" v-if="ed22info != undefined">
        <v-card-title class="btm-brdr-right">Информация с сервиса ED22</v-card-title>
        <v-card-text class="text--primary" v-if="ed22info.DOInfo != undefined">
          <div class="p-left-4 p-mrgn-left-4">
            {{ed22info.DOInfo.DO1.WarehouseOwner + ' ' +
          getcertdocstype(ed22info.DOInfo.DO1.CertKind) + ' (' + ed22info.DOInfo.DO1.CertNumber +
          ' от ' + fmtdate(ed22info.DOInfo.DO1.CertDate) + ')'}}
          </div>
          <div class="p-left-4 p-mrgn-left-4">
            <div :class="getclass(ed22info.DOInfo.DO1.Registration)">
              {{'ДО1: №&nbsp;' +  ed22info.DOInfo.DO1.DO1number + ' от '
            +
            fmtdate(ed22info.DOInfo.DO1.DO1date) + ' ' +
            getdocstate(ed22info.DOInfo.DO1.Registration)}}
            </div>
            <div class="dcl-main-date-block">
              <div class="dcl-child-date-left-block">
                {{'Дата приема товара на хранение:&nbsp;' +
              fmtdate(ed22info.DOInfo.DO1.GoodsKeepingLimit.AcceptDate) + '&nbsp;'
              +
              fmttime(ed22info.DOInfo.DO1.GoodsKeepingLimit.AcceptDate)}}
              </div>
              <div class="dcl-child-date-right-block">
                {{'Дата истечения срока хранения:&nbsp;' +
              fmtdate(ed22info.DOInfo.DO1.GoodsKeepingLimit.DeadLineDate)}}
              </div>
            </div>
          </div>
          <div class="p-left-4 p-mrgn-left-4">
            {{'Количество грузовых мест:&nbsp;' +
          ed22info.DOInfo.DO1.TotalCargoAndBruttoInfo.TotalPlaceNumber +
          '; вес брутто/объем:&nbsp;' +
          getbrutos(ed22info.DOInfo.DO1.TotalCargoAndBruttoInfo.Brutto)}}
          </div>
          <div class="p-left-4 p-mrgn-left-4">
            {{'Рег. №:&nbsp;' +
          ed22info.DOInfo.DO1.Registration.RegistrationNumber + '(' +
          fmttime(ed22info.DOInfo.DO1.Registration.RegistrationTime) + ') ' +
          ed22info.DOInfo.DO1.Registration.InspectorLastname + '&nbsp;' +
          ed22info.DOInfo.DO1.Registration.InspectorFirstname + '&nbsp;' +
          ed22info.DOInfo.DO1.Registration.InspectorMiddlename +
          ', ЛНП&nbsp;' + ed22info.DOInfo.DO1.Registration.InspectorLNP}}
          </div>
          <div v-for="item in ed22info.DOInfo.DO2" :key="item.DO2Number">
            <div class="p-left-4 p-mrgn-left-4">{{'ДО2: №&nbsp;' + item.DO2Number + ' от ' +
            fmtdate(item.DO2Date)}}
            </div>
            <div class="p-left-4 p-mrgn-left-4" v-for="regdoc in item.CustomsDocuments"
                 :key="regdoc.DocumentNumber">
              <div class="p-left-4 p-mrgn-left-4">{{'Выпускающий документ:&nbsp;' + regdoc.DocumentName +
              '&nbsp;' +
              regdoc.DocumentNumber}}
              </div>
            </div>
            <div class="p-left-4 p-mrgn-left-4">
              <div class="p-left-4 p-mrgn-left-4">
                {{'Рег. №:&nbsp;' + item.Registration.RegistrationNumber
              + ' (' +
              fmttime(item.Registration.RegistrationTime) + ') ' +
              item.Registration.InspectorLastname + '&nbsp;' +
              item.Registration.InspectorFirstname + '&nbsp;' +
              item.Registration.InspectorMiddlename + ', ЛНП&nbsp;' +
              item.Registration.InspectorLNP}}
              </div>
            </div>
          </div>
          
          <div class="p-left-4 p-mrgn-left-4" v-for="item in ed22info.ConsignmentInfoItems"
               :key="item.DocNumber">
            <div :class="getregclass(item.Status)">
              {{item.Status + '&nbsp;' + item.DocName + '&nbsp;№&nbsp;' +
            item.DocNumber}}
            </div>
            <div class="p-left-3 p-mrgn-left-4">{{item.Comment}}</div>
          </div>
          <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.Containers != undefined">
            <div>{{'Список контейнеров: ' + getcontainers(ed22info.Containers)}}</div>
            <div>{{'Транспорт: ' + gettransport(ed22info.Transport)}}</div>
            <div v-for="item in ed22info.Documents" :key="item.DocumentNumber">Транспортные документы:
              <div>
                {{'(' + item.DocumentCode + ')&nbsp;' + item.DocumentName + '&nbsp;' +
              item.DocumentNumber + ' от ' +
              fmtdate(item.DocumentDate)}}
              </div>
            </div>
          </div>
        </v-card-text>
      </div>
      <div class="mt-order-data-block" v-if="providerinfo != undefined">
        <v-card-title class="btm-brdr-right">Информация о мерах</v-card-title>
        <v-card-text class="text--primary">
          <div class="p-left-4 p-mrgn-left-4">
            <div class="dcl-main-date-block" v-if="providerinfo != undefined">
              <div class="dcl-child-date-left-block" v-if="providerinfo.BillOfLanding != undefined">
                {{'Утверждение позиции коносамента: ' +
              fmtdate(providerinfo.BillOfLanding) + ' ' +
              fmttime(providerinfo.BillOfLanding)}}
              </div>
              <div class="dcl-child-date-right-block" v-if="providerinfo.Unload != undefined">
                {{'Прием с моря: ' + fmtdate(providerinfo.Unload) + ' ' + fmttime(providerinfo.Unload)}}
              </div>
            </div>
          </div>
          <div class="p-left-4 p-mrgn-left-4">
            <div class="dcl-main-date-block" v-if="providerinfo.MidcExamRequest != null">
              <div class="dcl-child-date-left-block">{{'Заявлен на досмотр МИДК: ' +
              fmtdate(providerinfo.MidcExamRequest)}}
              </div>
              <div class="dcl-child-date-right-block">
                {{'Досмотр на МИДК: ' + fmtdate(
                ((providerinfo.MidcExam
                  !=
                  null) ? providerinfo.MidcExam[0] : null) )}}
              </div>
            </div>
          </div>
          <div class="p-left-4 p-mrgn-left-4" v-if="providerinfo.MidcExamRequest != null">
            <div class="dcl-main-date-block">
              <div class="dcl-child-date-left-block">
                {{'Постановка на площадку для проведения МИДК: ' + fmtdate(providerinfo.MidcExamIn)}}
              </div>
              <div class="dcl-child-date-right-block">
                {{'Убытие с площадки для проведения МИДК: ' + fmtdate(providerinfo.MidcExamOut)}}
              </div>
            </div>
          </div>
          
          <div class="p-left-4 p-mrgn-left-4" v-if="providerinfo.PhysExamRequest != null">
            <div class="dcl-main-date-block">
              <div class="dcl-child-date-left-block">
                {{'Заявлен на физический досмотр: ' + fmtdate(providerinfo.PhysExamRequest)}}
              </div>
              <div class="dcl-child-date-right-block">{{'Физический досмотр: ' +
              fmtdate(((providerinfo.PhysExam) ?
                providerinfo.PhysExam[0] : null) )}}
              </div>
            </div>
          </div>
          <div class="p-left-4 p-mrgn-left-4" v-if="providerinfo.PhysExamRequest != null">
            <div class="dcl-main-date-block">
              <div class="dcl-child-date-left-block">{{'Постановка на площадку для проведения досмотра: '
              +
              fmtdate(providerinfo.PhysExamIn)}}
              </div>
              <div class="dcl-child-date-right-block">{{'Убытие с площадки для проведения досмотра: ' +
              fmtdate(providerinfo.PhysExamOut)}}
              </div>
            </div>
          </div>
          
          <div class="p-left-4 p-mrgn-left-4" v-if="providerinfo.PhysExamRequest != null">
            {{'Пломба, процент выемки: ' + ((providerinfo.PhysExamComment !=
            null) ? providerinfo.PhysExamComment : '') }}
          </div>
          <div class="p-left-4 p-mrgn-left-4" v-if="providerinfo.Weighting != null">
            <div class="dcl-main-date-block">
              <div class="dcl-child-date-left-block">
                {{'Взвешивание: ' +
              fmtdate(providerinfo.Weighting)}}
              </div>
              <div class="dcl-child-date-right-block">{{'Вес: ' + ((providerinfo.WeightingComment != null)
                ? providerinfo.WeightingComment : '')}}
              </div>
            </div>
          </div>
          <div class="p-left-4 p-mrgn-left-4">
            <div class="dcl-main-date-block">
              <div class="dcl-child-date-left-block" v-if="providerinfo.OutRequest != undefined">
                {{'Заявка на вывоз: ' +
              fmtdate(providerinfo.OutRequest) + ' ' +
              fmttime(providerinfo.OutRequest)}}
              </div>
              <div class="dcl-child-date-right-block" v-if="providerinfo.Out != undefined">
                {{'Вывоз: ' + fmtdate(providerinfo.Out) + ' ' +
              fmttime(providerinfo.Out)}}
              </div>
            </div>
          </div>
        </v-card-text>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
export default {
  name: "DclRequestEd22Info.vue",
  props: {
    ed22info: {
      type: Object
    },
    cont_num: {
      type: String
    },
  },
  data() {
    return {
      providerinfo: [],
      region: 107,
      stype: 'LikeMatch',
      provider_id: '',
    }
  },
  
  mounted() {
    this.getproviderinfo()
  },
  
  watch: {
    ed22info: function (value) {
      this.getproviderinfo(this.cont_num, value.WhInfoProvider)
    },
  },
  
  methods: {
    getcertdocstype(CertKind) {
      switch (CertKind) {
        case "lic_Certificate":
          return "Свидетельство о включении в реестр владельцев СВХ ";
        case "lic_Permition":
          return "Разрешение ТО на временное хранение в ином месте";
        default:
          return "Номер приказа о создании";
      }
    },
    fmtdate(val) {
      if (val != null)
        return (new Date(val)).toLocaleString("ru", {year: 'numeric', month: 'numeric', day: 'numeric'});
      else
        return ''
    },
    fmttime(val) {
      if (val != null)
        return (new Date(val)).toLocaleString("ru", {hour: 'numeric', minute: 'numeric'});
      else
        return '';
    },
    getclass(val) {
      return (val != null) ? "p-block p-green" : "p-block p-red";
    },
    
    getregclass(val) {
      return (val != 'Выпуск разрешён') ? 'p-block p-yellow' : 'p-block p-light-green';
    },
    getdocstate(val) {
      return (val != null) ? ' - Зарегистрирована' : ' - Не зарегистрирована';
    },
    getbrutos(bruttos) {
      var data = []
      for (var i = 0; bruttos.length; i++) {
        if (i == bruttos.length) break
        data.push(bruttos[i].Value + ' ' + bruttos[i].Key)
      }
      return data.join().replace(/,/g, '')
    },
    getproviderinfo(cont_num, provider_id) {
      this.providerinfo = []
      this.$postapi(this.$address + this.$getters.getProviderInfo.uri, {
        method: this.$getters.getProviderInfo.name,
        pattern: cont_num,
        region: this.region,
        WhInfoProvider: provider_id
      }).then(data => {
        this.providerinfo = data
      });
    },
    getcontainers(containers) {
      var data = []
      for (var i = 0; containers.length; i++) {
        if (i == containers.length) break
        data.push(containers[i])
      }
      return (data.join()).replace(/,/g, ', ')
    },
    gettransport(trdata) {
      var data = []
      for (var i = 0; trdata.length; i++) {
        if (i == trdata.length) break
        data.push(trdata[i].TransportIdentifier)
      }
      return data.join().replace(/,/g, ', ')
    },
  },
}
</script>

<style scoped>
p {
  margin-left: 5px;
}

.p-10 {
  margin-left: 15px;
  font-size: 11pt;
}

.p-block {
  border-bottom: 1px solid #5ebdf3;
  background-color: #e5f0f7;
}

.p-red {
  background-color: #FFBCBC;
}

.p-green {
  background-color: #B3E283;
}

.p-yellow {
  background-color: #FFED99;
}

.p-light-green {
  background-color: #CEE5D0;
}

.table-head {
  text-align: center;
  background-color: #e5f0f7;
  font-weight: bold;
}

.grid-center {
  text-align: center;
}

.grid-center td {
  padding: 15px;
}

.msg-block {
  font-size: 10pt;
}

.btn {
  background-color: #e5f0f7;
  color: #444;
  cursor: pointer;
  padding: 8px;
  width: 250px;
  text-align: center;
  outline: none;
  font-size: 15px;
  border: 1px solid #5ebdf3;
}

.text-box {
  padding: 8px;
  width: 350px;
}

.btn:hover {
  background-color: #5ebdf3;
  color: #444;
}

.btn:active {
  background-color: #eee;
  color: #444;
}

.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #ccc;
}

.content {
  padding: 0 18px;
  overflow: hidden;
  background-color: #f1f1f1;
}

.hidden {
  display: none;
}

.btm-brdr-right {
  border-bottom: 0.05208vw solid #008cd0;
  text-align: right;
  margin-right: 2%;
  margin-left: 2%;
}

.mt-order-data {
  display: flex;
  flex-direction: column;
}

.mt-order-main-data {
  display: flex;
  flex-direction: row;
}

.mt-order-data-block {
  background-color: #ffffff;
}

.p-left-4 {
  line-height: 1.5vw;
  text-align: left;
  margin-right: 4%;
  font-size: 0.7vw;
  text-overflow: ellipsis;
}

.p-left-1 {
  line-height: 1.03vw;
  text-align: left;
  margin-right: 4%;
  font-size: 0.75vw;
  text-overflow: ellipsis;
}

.p-left-3 {
  line-height: 1.03vw;
  text-align: left;
  margin-right: 4%;
  font-size: 0.6vw;
  text-overflow: ellipsis;
}

.p-mrgn-left-4 {
  margin-left: 4%;
}

.mt-order-main-data-column {
  width: 50%;
  margin-left: 2%;
  margin-right: 2%;
}

.mt-order-full-data-column {
  width: 98%;
  margin-left: 2%;
  margin-right: 2%;
}

.br-margin {
  /*        margin-bottom: 4.35%; */
}

.v-input--checkbox {
  height: 25px !important;
}

.v-input--selection-controls {
  margin-top: 0.4% !important;
  padding-top: 0px !important;
}

.btn-right {
  flex-direction: column;
  float: right;
  margin: auto;
  line-height: 40px;
}

.request-title {
  flex-direction: row;
  width: 100%;
  margin: auto;
  line-height: 40px;
}

.title-text {
  flex-direction: column;
  float: left;
  line-height: 40px;
  margin: auto;
}

.btn-height {
  min-height: 2.5vw;
}

.dcl-main-date-block {
  flex-direction: row;
  width: 100%;
  line-height: 1.5vw;
  height: 1.5vw;
}

.dcl-child-date-left-block {
  flex-direction: column;
  width: 50%;
  float: left;
}

.dcl-child-date-right-block {
  flex-direction: column;
  width: 50%;
  float: right;
  text-align: left;
}

.v-card__title {
  padding: 0.4% !important;
}

.v-card__text {
  padding: 0px !important;
}
</style>
