<template>
  <div class="cvtb-edit">
    <v-text-field
      v-model="value"
      :label="element.caption"
      :type="element.type"
      :rules="element.rules"
      :required="element.required"
      :hint="element.hint"
      :outlined="outlined"
      :disabled="element.disabled"
      class="cvtb-edit"
      color="var(--main-orange)"
      @change="OnChange"
      @wheel.prevent
    ></v-text-field>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CVTBEdit.vue",
  props: {
    element:{
      type: Object
    },
  
    outlined: {
      type: Boolean,
      default: true,
      required: false
    },
    
    val: {
      type: [String, Object, Number],
      required: true
    }
  },
  data: () => ({
    // value: '',
  }),
  
  computed: {
    value: {
      get() {
        return this.val
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  
  watch: {
    val: function () {
      this.value = this.val
    },
    deep: true
  },
  
  methods:{
    OnChange(){
      this.$emit("change", { element: this.element.name, value: this.value })
    },
  }
}
</script>

<style scoped lang="scss">
.cvtb-edit {
  width: 100%;
}
</style>
