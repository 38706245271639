<script>
import { request_form_spec } from "@/components/jsons/request-form";
import { bus } from "@/router/bus";
import MainInfoTab from "@/components/cabinet/request-list/side-form-tabs/MainInfoTab.vue";
import FilesTab from "@/components/cabinet/request-list/side-form-tabs/FilesTab.vue";
import CommentTab from "./side-form-tabs/CommentTab.vue";
import ServicesTab from "@/components/cabinet/request-list/side-form-tabs/ServicesTab.vue";
import DclRequestEd22 from "@/components/cabinet/request-list/side-form-tabs/DclRequestEd22.vue";
import CommentTabNew from "@/components/cabinet/request-list/side-form-tabs/CommentTabNew.vue";
import FtpUploader from "../../common/FtpUploader.vue";
import AppLoader from "@/components/ui/AppLoader.vue";
import ExtraExpencesTab from "./side-form-tabs/ExtraExpencesTab.vue";

export default {
  name: "CVRequestSideForm.vue",
  components: {
    FtpUploader,
    FilesTab,
    MainInfoTab,
    CommentTab,
    ServicesTab,
    DclRequestEd22,
    CommentTabNew,
    ExtraExpencesTab,
    AppLoader
  },
  
  props: {
    document: {
      type: [Array, Object],
    },
  },
  
  data: () => ({
    elements: request_form_spec,
    clientComments: [],
    agentComments: [],
    customComments: [],
    newCommentCount: 0,
    order: {
      files: {
        goods: [],
        commerce: [],
        transport: [],
        technic: [],
        access: [],
        price: [],
        other: [],
      }
    },
  
    files_name: [],
  
    showStatus: false,
    showFileForm: false,
    temp_id: Math.floor(Math.random() * 1e8),
    fileName: null,
    order_file: {
      caption: "Загрузить документ",
      name: "order_file",
      multiple: true,
      disabled: true,
      alt: "Добавьте файл компании",
    },
  }),
  
  watch:{
    document: {
      document: function(value){
        this.getClientComments()
        this.getAgentComments()
         this.getDclComments()
        this.doc = value
      },
      handler() {
        this.order = this.document
        this.getClientComments()
        this.getAgentComments()
        this.getDclComments()
      }
    },
  },
  
  computed: {
    isAgentDialog() {
      return this.$_getsetting('is_show_bm_messages') == 1
    }
  },
  
  mounted() {
    this.order = this.document
  
    bus.$on("recalccommentscount", () => {
      this.getClientComments()
    });
  },
  
  methods:{
    reloadData(tab_id){
      clearInterval(this.commentIntervalId)
      clearInterval(this.historyIntervalId)
      switch (tab_id) {
        case 1: this.getClientComments(); break
        case 2: this.getDclComments(); break
        case 3: this.getAgentComments(); break
        case 5: {
          this.doc = this.document;
        } break
      }
    },

    parseMessage(data) {
      let parseData = JSON.parse(data.message);
      return (parseData && parseData !== 'null' && parseData !== 'false') ? parseData : [];
    },
    
    setFilesName(data) {
      this.showStatus = false
      this.files_name.push(data)
    },
  
    fileUploaded() {
      this.$postapi(this.$address + this.$setters.setFilesToOrder.uri, {
        method: this.$setters.setFilesToOrder.name,
        temp_id: this.temp_id,
        files_name: this.files_name,
        ord_id: this.document.ord_id,
      }).then(data => {
        if (data.error == 0){
          this.showStatus = true
          setTimeout(() => {
            this.showFileForm = false
            this.showStatus = false
            this.files_name = []
          }, 1500);
        }
      });
    },
    
    checkAccess(){
      // console.log((/Счет[ \-_A-Za-zА-Яа-я0-9]*.pdf/gm).exec(element.titlename))
//      if ((/Счет[ \-_A-Za-zА-Яа-я0-9]*.pdf/gm).exec(element.titlename) != null){
        return true
//      } else
//        return (/\d{8}_\d{6}_\d{7}.pdf/gm).exec(element.titlename) != null
    },
    
    filterList(data){
      if (data != false) {
        return data.filter(this.checkAccess)
      }
    },
    
    calcNewComments(data){
      let cnt = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i].is_vizited == 0)
          cnt++
      }
      this.newCommentCount = cnt
    },
    
    getDclComments() {
      this.customComments = []
      this.$postapi(this.$address + this.$getters.getCustomsRequestComments.uri, {
        method: this.$getters.getCustomsRequestComments.name,
        ord_id: this.document.ord_id
      }).then(data => {
        if (data.error == 0) {
          this.customComments = this.parseMessage(data);
//          this.calcnewcomments(this.customComments)
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    getClientComments() {
      this.clientComments = []
      this.$postapi(this.$address + this.$getters.getClientRequestComments.uri, {
        method: this.$getters.getClientRequestComments.name,
        ord_id: this.document.ord_id
      }).then(data => {
        if (data.error == 0) {
          this.clientComments = this.parseMessage(data).reverse();
          this.clientComments.sort(function (a, b) {
            return a.id - b.id;
          });
          this.calcNewComments(this.clientComments)
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    getAgentComments() {
      this.agentComments = []
      this.$postapi(this.$address + this.$getters.getBookingRequestComments.uri, {
        method: this.$getters.getBookingRequestComments.name,
        ord_id: this.document.ord_id
      }).then(data => {
        if (data.error == 0) {
          this.agentComments = this.parseMessage(data).reverse();
          this.agentComments.sort(function (a, b) {
            return b.id - a.id;
          });
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    OnClickButton(id) {
      switch (id){
        case 25: bus.$emit("clickChildButton", { id : id}); break
        case 34: this.savedata(); break //bus.$emit("clickChildButton", { id : id}); break
      }
    },
    
    OnChange(data) {
      this.order[data.element] = data.value
    },
    
    OnFilesInput(data) {
      this.order.files[data.element] = data.value
    },
    
    OnDateChange(data) {
      this.order[data.element] = data.value
    },
    
    toArray(value) {
      if (value != undefined){
        if (!isNaN(value)) {
          let values = []
          values.push(value.toString())
          return values
        } else
          return (value != undefined) ? value.split(',') : []
      }
    }
  }
}
</script>

<template>
  <div class="mt-editor-form">
    <div class="mt-order-fixed-block">
      <div class="mt-order-close-wdw">
        <div class="mt-order-title">
          {{ document.req_number}}
        </div>
  
        <v-btn
          @click="$emit('close')"
          class="mt-order-close-btn"
          icon
        >
          <img class="video-slider__arrow" src="../../../assets/img/icons/mdi/mdi-close.svg" alt="mdi-close">
        </v-btn>

        <v-tabs
          grow
          color="var(--bg-dark)"
          class="tabs-container"
        >
          <v-tab>Основное</v-tab>
          <v-tab>Финансы</v-tab>
          <v-tab>Доп. расходы</v-tab>
          <v-tab
            @click="reloadData(1)"
          >
            Комментарии
            <v-badge
              v-if="newCommentCount > 0"
              color="#FF6347"
              :content="newCommentCount"
            >
            </v-badge>
          </v-tab>
          <v-tab v-if="isAgentDialog == true"  @click="reloadData(3)">Переписка с агентом</v-tab>
          <v-tab @click="reloadData(2)">Таможня</v-tab>
<!--          <v-tab>Финансы</v-tab>-->
          <v-tab>Файлы</v-tab>
          <v-tab>СВХ</v-tab>
          <v-tab-item class="tab-content">
            <MainInfoTab
              class="mt-20"
              v-if="document.ord_id"
              :order="document"
            />
            <AppLoader v-else :size="100" class="tab-loader" />
          </v-tab-item>
          
          <v-tab-item class="tab-content">
            <ServicesTab
                class="mt-20"
                v-if="document.ord_id"
                :order="document"
            />
            <AppLoader v-else :size="100" class="tab-loader" />
          </v-tab-item>
          
          <v-tab-item class="tab-content">
            <ExtraExpencesTab
              class="mt-20"
              v-if="document.ord_id"
              :order_id="document.ord_id"
            />
            <AppLoader v-else :size="100" class="tab-loader" />
          </v-tab-item>
          
          <v-tab-item class="tab-content">
            <CommentTabNew
              v-if="clientComments.length > 0"
              class="comment-tab"
              :comments="clientComments"
              :ord_id="Number(document.ord_id)"
            />
            <div class="not-found-msg" v-else >Комментарии отсутствуют</div>
          </v-tab-item>
          
          <v-tab-item v-if="isAgentDialog == true">
            <CommentTabNew class="comment-tab" :chatFooter="false" :comments="agentComments" :ord_id="Number(document.ord_id)"/>
          </v-tab-item>

          <v-tab-item class="tab-content">
            <CommentTab
                v-if="customComments.length > 0"
                :ord_id="Number(document.ord_id)"
                :comments="customComments"
            />
            <div class="not-found-msg" v-else >Комментарии отсутствуют</div>
          </v-tab-item>

<!--          <v-tab-item>-->
<!--            <FinanceTab-->
<!--              class="mt-20"-->
<!--              :document="document"-->
<!--              :invoices="document.invoices"-->
<!--              :payments="document.payments"-->
<!--            />-->
<!--          </v-tab-item>-->

          <v-tab-item class="tab-content">
            <FilesTab
              v-if="document.files"
              :files ="filterList(document.files ? JSON.parse(document.files) : [])"
              @showFileForm="showFileForm = true"
            />
            <AppLoader v-else :size="100" class="tab-loader" />
          </v-tab-item>

          <v-tab-item >
            <DclRequestEd22 :order="document"/>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    
    <v-dialog
      width="40%"
      height="60%"
      v-model="showFileForm"
    >
      <v-card
        class="py-10 px-6"
        v-if="showFileForm === true"
      >
        <div class="text-h5 px-8 mt-3 row justify-space-between">
          Выберите документ организации
          <v-btn
            icon
            rounded
            @click="showFileForm = false, showStatus = false"
            class="file-tab__upload"
          >
            <img src="../../../assets/img/icons/mdi/mdi-close.svg" alt="mdi-close">
          </v-btn>
        </div>
        <v-form class="pa-4">
          <FtpUploader
            @fileUploaded="setFilesName"
            :element="order_file"
            :order_key="temp_id"
          />
          <span
            v-if="showStatus && showFileForm === true && files_name.length > 1"
            class="mt-2"
          >
            Файлы загружены, мы разместим их в течение 15 минут
          </span>
          <span
            v-if="showStatus && showFileForm === true && files_name.length == 1"
            class="mt-2"
          >
            Файл загружен, мы разместим его в течение 15 минут
          </span>
        </v-form>
      
        <v-card-actions class="mt-2 mb-0">
          <v-spacer></v-spacer>
          <v-btn
            color="var(--main-bg-color)"
            text
            @click="fileUploaded"
          >
            Сохранить
          </v-btn>
          <v-btn
            color="var(--main-bg-color)"
            text
            @click="showFileForm = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<style scoped>
.mt-editor-form {
  background-color: #fff;
  margin-bottom: 0.4%;
  overflow: hidden;
}

.mt-order-close-wdw {
  text-align: right;
  margin: 0.4%;
  min-height: 2.5vw;
}

.mt-order-close-btn {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 2000;
}

.tabs-container {
  max-width: 98%;
}

.comment-tab {
  /*height: 800px;*/
  /*height: 80vh;*/
  overflow: auto;
  /*position: relative;*/
}

.mt-order-form-data{
  max-height: 36.9vw;
  padding: 2%;
}
.mt-order-fixed-block {
  width: 98%;
  margin: auto;
  min-height: 33.8541vw;
}

.v-slide-group__next, .v-slide-group__prev {
  align-items: center;
  display: flex;
  flex: 0 1 45px;
  justify-content: center;
  min-width: 20px;
}

.btn-height {
  min-height: 32px !important;
  min-width: 32px !important;
  margin-left: 16px !important;
}
.cv-row-div{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.cv-row-div div{
  margin-right: 1.61%;
  width: 50%;
}
.icon-size-fix {
  font-size: 12pt !important;
}

.v-window__container {
  margin-top: 15px;
}

.mt-order-close-btn img{
  height: 24px;
  width: 24px;
}
.file-tab__upload img{
  height: 24px;
  width: 24px;
}

.not-found-msg{
  text-align: center;
  margin-top: 100px;
  color: rgba(102,102,102,.87);
}
.tab-content{
  min-height: 500px;
  position: relative;
}

.tab-loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.not-found-msg{
  text-align: center;
  margin-top: 100px;
  color: rgba(102,102,102,.87);
}
</style>
