<template>
  <div class="file-input">
    <v-file-input
      counter
      truncate-length="30"
      :show-size="element.show_size"
      :multiple="element.multiple"
      :outlined="outlined"
      :rounded="rounded"
      :messages="element.label"
      :label="element.caption"
      :rules="element.rules"
      prepend-icon=""
      prepend-inner-icon="mdi-paperclip"
      v-model="result"
      validate-on-blur
      height="auto"
      color="var(--main-orange)"
      :loading="loading"
      @change="onChange()"
    >
      <template v-slot:selection="{ index, text, file }">
        <v-chip
          color="var(--main-orange)"
          link
          light
          pill
          close
          @click:close="removeFile(file)"
        >
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>

<!--    <v-btn-->
<!--      flat-->
<!--      @click="sendFile"-->
<!--      small-->
<!--      class="mt-6"-->
<!--      text-->
<!--      :rounded="rounded"-->
<!--      color="var(&#45;&#45;main-orange)"-->
<!--      :disabled="result.length === 0 ? true : false"-->
<!--    >-->
<!--      Загрузить-->
<!--      <v-icon-->
<!--        right-->
<!--        dark-->
<!--      > mdi-cloud-upload-->
<!--      </v-icon>-->
<!--    </v-btn>-->
  </div>
</template>

<script>
export default {
  name: "FtpUploader.vue",
  
  props: {
    element:{
      type: Object
    },
    
    rounded: {
      type: Boolean,
      default: false,
    },
    
    outlined: {
      type: Boolean,
      default: false,
    },
    
    order_key: {
      type: Number
    }
  },
  
  data: () => ({
    result: [],
    loading: false,
    uploadPercentage: 0,
    formData: new FormData()
  }),
  
  mounted() {
    this.resetInput()
  },
  
  methods: {
    onChange() {
      this.formData.append("temp_id", this.order_key)
      
      if (this.element.multiple == true) {
        for (let i = 0; i < this.result.length; i++) {
          this.formData.append(this.result[i].name, this.result[i]);
        }
      }
      
      this.sendFile()
    },
    
    sendFile() {
      this.loading = true
      
      this.$postapi(this.$address + this.$getters.ftpposttest.uri, this.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }
        ).then(() => {
          if (this.element.multiple == true) {
            for (let i = 0; i < this.result.length; i++) {
              this.$emit('fileUploaded', this.result[i].name)
            }
          } else {
            this.$emit('fileUploaded', this.result.name)
          }
          this.loading = false
      })
    },
    
    removeFile(file) {
      if (this.element.multiple == true) {
        let index = this.result.findIndex(el => el.name == file.name);
        this.result.splice(index, 1)
      } else {
        this.result = []
      }
      this.loading = false
    },
    
    resetInput() {
      this.result = []
      this.loading = false
      this.formData = new FormData()
    }
  },
}
</script>

<style scoped lang="scss">
.file-input {
  display: flex;
}
</style>
