import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pa-4"},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Запрос акта сверки ")]),_c(VCardSubtitle,{staticClass:"mt-2"},[_vm._v(" Укажите период запрашиваемой сверки ")]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.setCompleteDocRequest.apply(null, arguments)}}},[_c(VCardActions,{staticClass:"mx-3"},[_c('CVTBDateInput',{staticClass:"mr-3",attrs:{"element":_vm.elements.date_from,"val":_vm.date_from},on:{"input":function($event){_vm.date_from = $event},"selected":function($event){_vm.date_from = $event.value}}}),_c('CVTBDateInput',{staticClass:"ml-3",attrs:{"element":_vm.elements.date_to,"val":_vm.date_to},on:{"input":function($event){_vm.date_to= $event},"selected":function($event){_vm.date_to = $event.value}}})],1),(_vm.showStatus == true)?_c('span',{staticClass:"mx-5"},[_vm._v("Запрос подан в отдел бухгалтерии")]):_vm._e(),_c(VCardActions,{staticClass:"mt-8"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"var(--main-bg-color)","text":"","type":"submit"}},[_vm._v(" Запросить ")]),_c(VBtn,{attrs:{"color":"var(--main-bg-color)","text":"","type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Закрыть ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }