<script>

export default {
  name: "CommentsChatFooter.vue",
  
  props: {
    comment: {
      type: Object
    }
  },
  
  data() {
    return {
      message: ''
    }
  },
  
  methods: {
  },
}
</script>

<template>
  <div
    class="chat-footer"
  >
    <div class="chat-footer__container">
      
      <v-textarea
        v-model="message"
        class="chat-footer__container-input"
        color="black"
        background-color="white"
        rounded
        label="Напишите комментарий..."
        auto-grow
        rows="1"
        row-height="15"
        dense
        single-line
      />
    </div>
    
    <v-btn
      icon
      class="chat-footer__btn"
      @click="[$emit('sendMessage', message), message = '']"
    >
      <img class="chat-footer__send-btn" src="@/assets/img/icons/mdi/mdi-send-variant.svg"/>
    </v-btn>
  </div>
</template>

<style scoped lang="scss">

.chat-footer {
  width: 100%;
  background: #E0E0E0;
  display: flex;
  align-items: center;
  align-content: center;
  overflow: auto;
  padding: 15px 0 15px;
  justify-content: space-around;
  
  &__container {
    display: flex;
    background: white;
    width: 85%;
    border-radius: 12px;
    margin: 0  0 ;
    padding: 12px 0 -12px 0;
    overflow: auto;
    max-height: 150px;
    
    &-input {
      margin: 12px 0 -8px;
    }
  }
  
  &__btn {
    justify-self: center;
  }
}
.chat-footer__send-btn{
  width: 24px;
  height: 24px;
}
</style>
