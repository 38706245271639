<script>
export default {
  name: "RequestTimeline",
  
  props: {
    document: {
      type: [Object, Array]
    }
  },
  
  data() {
    return {
      events_sort : []
    }
  },
  
  watch: {
    document: {
      handler: function() {
        this.sortEvents()
      },
      deep: true
    }
  },
  
  mounted() {
    this.sortEvents()
  },
  
  methods: {
    sortEvents() {
      this.events_sort = this.document.events.slice().sort(function (a, b) {
        if (parseInt(a.sort) == parseInt(b.sort))
          return 0;
        if (parseInt(a.sort) < parseInt(b.sort))
          return -1;
        if (parseInt(a.sort) > parseInt(b.sort))
          return 1;
      });
      if(this.events_sort.length == 11 ) {
        this.events_sort.splice(0, 0, this.events_sort.splice(1, 1)[0]);
      } else if (this.events_sort.length > 11) {
        this.events_sort.splice(1, 0, this.events_sort.splice(2, 1)[0]);
      }
    },
    
    getdate(value){
      if (value != null)
        return (new Date(value)).toLocaleString("ru", { year: 'numeric', month: 'numeric', day: 'numeric', timezone: 'UTC' });
      else
        return ' '
    },
    
    getclassbydate(value){
      if (value != null)
        return (new Date(value) < new Date()) ? 'tm-item-img' : 'tm-item-img tm-item-img-gs'
      else
        return 'tm-item-img tm-item-img-gs'
    },
    
    getimgname(value){
      return value + '.png'
    },
    
    getfiltereddata(data){
      return data.filter(el => [3,2,4,7,9,10,11,12].includes(+el.sort))// != null)
    }
  }
}
</script>

<template>
  <div class="mt-info-block">
    <div class="mt-order-timeline">
      <div class="timeline">
        <div class="tm-item" v-for="item in getfiltereddata(events_sort)" :key="item.compaund_id" >
          <div class="tm-item-header">{{item.event_desc}}</div>
          <img
            :alt="item.event_desc"
            :title="item.event_desc"
            :class="getclassbydate(item.event_date)"
            :src="`/rate-timeline/${getimgname(item.event_name)}`"
          />
          <p class="tm-item-date">{{getdate(item.event_date)}}</p>
        </div>
      </div>
      <hr/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mt-info-block{
  display: flex;
  flex-direction: row;
  width: 100%;
  // min-height: 6.5625vw;
}

.mt-order-timeline{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.timeline {
  width: 98%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  position: relative;
  z-index: 1;
}

hr {
  border-bottom: 0.05208vw dotted #d3d3d3;
  background: #1976d2;
  width: 87%;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 22px;
}

.tm-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 88px;  
  gap: 5px 0;
  padding: 0 5px;

  @media (min-width: 1800px) {
    gap: 10px 0;
  }
}

.tm-item-header{
  font-size: 0.6vw;
  margin-bottom: 0px;
  white-space: normal;
  margin: unset;
  text-align: center;
  line-height: normal;
  height: 25px;

  display: flex;
  align-items: flex-end;

  > p {

  }
}

.tm-item-date{
  font-size: 0.7vw;
  margin-bottom: 0px;
  min-height: 20px;
}

.tm-item-img{
  width: 3.125vw;
  height: 3.125vw;
  line-height: 3.125vw;
  background-color: #f5f8fd;
  border-radius: 50%;
}

.tm-item-img-gs{
  filter: grayscale(100%);
}
</style>
