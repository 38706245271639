<template>
  <v-menu
    class="cvtb-date"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="cvtb-date-input"
        v-model="dateFormatted"
        prepend-inner-icon="mdi-calendar"
        flat
        outlined
        readonly
        :dense="dense"
        :rules="element.rules"
        :label="element.caption"
        color="var(--main-orange)"
        v-bind="attrs"
        v-on="on"
        @blur="date = parseDate(dateFormatted)"
      ></v-text-field>
    
    </template>
    
    <v-date-picker
      first-day-of-week="1"
      color="var(--main-orange)"
      v-model="date"
      no-title
      scrollable
      :allowed-dates="allowedDates"
      @input="menu = false"
      @change="OnChange(element.name)"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "CVTBDateInput",
  props: {
    element: {
      type: Object,
    },
    
    dense: {
      type: Boolean,
      default: false
    },
    
    val: {
      type: [Date, String]
    },
  
    allowedDates: {
      type: [Function, String, Object],
      required: false
    }
  },
  
  data: vm => ({
    date: null,
    dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    menu: false,
  }),
  
  
  mounted() {
    this.date = this.val.toISOString().substr(0, 10)
  },
  
  watch: {
    val: function () {
      this.date = this.val
    },
    deep: true,
    
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  
  methods:{
    formatDate(date) {
      if (!date) return null
      
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    
    parseDate(date) {
      if (!date) return null
      
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    
    OnChange(name){
      this.$emit("selected", { element: name, value: this.date })
    }
  },
}
</script>

<style scoped lang="scss">
.cvtb-date {
  width: 100%;
  
  &-input {
    font-family: $font-family;
   }
}
</style>

