<template>
    <div>
        <v-list-item-content>
            <div class="mt-order-data-block" v-if="ed22info.WHInfo != undefined">
                <v-card-title class="btm-brdr-right">Информация с Альта СВХ</v-card-title>
                <v-card-text class="text--primary">
                    <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.WHInfo.name != undefined">{{ed22info.WHInfo.name + '&nbsp;(' + ed22info.WHInfo.certkind + ed22info.WHInfo.certnum + ' от ' + ed22info.WHInfo.certdate + ')'}}</div>
                    <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.WHDocument != undefined">{{'Дата приема товара на хранение:&nbsp;' + ed22info.WHDocument.docdate}}</div>
                    <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.GoodsInfo != undefined">{{'Количество грузовых мест:&nbsp;' + ed22info.GoodsInfo.places + '; вес брутто/объем:&nbsp;' +  ed22info.GoodsInfo.weigth}}</div>
                    <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.WHDocument != undefined">{{'Рег. №:&nbsp;' + ed22info.WHDocument.docnumber}}</div>
                    <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.WHInfo.name != undefined">{{'Список контейнеров: ' + getcontainers(ed22info.Containers)}}</div>
                    <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.Transport.name != undefined">{{'Транспорт: ' + ed22info.Transport.name}}</div>
                    <div class="p-left-4 p-mrgn-left-4" v-if="ed22info.TransportDocument != undefined">{{'Транспортные документы: ' + ed22info.TransportDocument.docnumber + ' от ' + ed22info.TransportDocument.docdate}}</div>
                    <button type="button" class="collapsible" v-if="ed22info.Messages != undefined" @click="showmessagesblock()">{{'Показать сообщения (' + ed22info.Messages.length + ')'}}</button>
                    <div id="msgs-block" class="content" v-show="showmessages">
                        <div class="msg-block" v-for="item in ed22info.Messages" :key="item.msg_docnumber">
                            <div class="p-left-1 p-mrgn-left-4">{{'Тип сообщения: &nbsp;' + item.msg_type}}</div>
                            <div class="p-left-1 p-mrgn-left-4">{{item.reg_doctype + ': № ' + item.msg_docnumber}}</div>
                            <div class="p-left-1 p-mrgn-left-4">{{item.place_count}}</div>
                            <div class="p-left-1 p-mrgn-left-4">{{item.goods_weight}}}</div>
                            <div :class="getmsgclass(item.msg_type)">{{item.msg_status}}</div>
                        </div>
                    </div>
                </v-card-text>
            </div>
         </v-list-item-content>
    </div>
</template>

<script>
    export default {
        name: "DclRequestAltaInfo.vue",
        props: {
            ed22info: {
                type: Object
            }
        },
        data() {
            return {
                showmessages: false,
            }
        },
        methods:{
            getclass(val) {
                return (val != null) ? "p-block p-green" : "p-block p-red";
            },
            getdocstate(val) {
                return (val != null) ? ' - Зарегистрирована' : ' - Не зарегистрирована';
            },
            getmsgclass(val) {
                return (val != 'CMN.13030') ? 'p-block p-yellow' : 'p-block p-green';
            },
            getcontainers(containers) {
                var data = []
                for (var i = 0; containers.length; i++) {
                    if (i == containers.length) break
                    data.push(containers[i])
                }
                return (data.join()).replace(/,/g, ', ')
            },
            showmessagesblock(){
                this.showmessages = !this.showmessages
            },
        },
    }
</script>

<style scoped>
    p{
        margin-left: 5px;
    }
    .p-10{
        margin-left: 15px;
        font-size: 11pt;
    }
    .p-block{
        border-bottom: 1px solid #5ebdf3;
        background-color: #e5f0f7;
    }
    .p-red{
        background-color: #FFBCBC;
    }
    .p-green{
        background-color: #B3E283;
    }
    .p-yellow{
        background-color: #FFED99;
    }
    .p-light-green{
        background-color: #CEE5D0;
    }
    .table-head{
        text-align: center;
        background-color: #e5f0f7;
        font-weight: bold;
    }
    .grid-center{
        text-align: center;
    }
    .grid-center td{
        padding: 15px;
    }
    .msg-block{
        font-size: 10pt;
    }
    .btn{
        background-color: #e5f0f7;
        color: #444;
        cursor: pointer;
        padding: 8px;
        width: 250px;
        text-align: center;
        outline: none;
        font-size: 15px;
        border: 1px solid #5ebdf3;
    }
    .text-box{
        padding: 8px;
        width: 350px;
    }
    .btn:hover{
        background-color: #5ebdf3;
        color: #444;
    }
    .btn:active{
        background-color: #eee;
        color: #444;
    }
    .collapsible {
        background-color: #eee;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
    }
    .active, .collapsible:hover {
        background-color: #ccc;
    }
    .content {
        padding: 0 18px;
        overflow: hidden;
        background-color: #f1f1f1;
    }
    .hidden{
        display: none;
    }
    .btm-brdr-right {
        border-bottom: 0.05208vw solid #008cd0;
        text-align: right;
        margin-right: 2%;
        margin-left: 2%;
    }

    .mt-order-data {
        display: flex;
        flex-direction: column;
    }

    .mt-order-main-data {
        display: flex;
        flex-direction: row;
    }

    .mt-order-data-block {
        background-color: #ffffff;
    }

    .p-left-4 {
        line-height: 1.5vw;
        text-align: left;
        margin-right: 4%;
        font-size: 0.75vw;
        text-overflow: ellipsis;
    }
    .p-left-1 {
        line-height: 1.03vw;
        text-align: left;
        margin-right: 4%;
        font-size: 0.7vw;
        text-overflow: ellipsis;
    }
    .p-mrgn-left-4 {
        margin-left: 4%;
    }

    .mt-order-main-data-column {
        width: 50%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .mt-order-full-data-column {
        width: 98%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .br-margin {
        /*        margin-bottom: 4.35%; */
    }

    .v-input--checkbox {
        height: 25px !important;
    }

    .v-input--selection-controls {
        margin-top: 0.4% !important;
        padding-top: 0px !important;
    }

    .btn-right {
        flex-direction: column;
        float: right;
        margin: auto;
        line-height: 40px;
    }

    .request-title {
        flex-direction: row;
        width: 100%;
        margin: auto;
        line-height: 40px;
    }

    .title-text {
        flex-direction: column;
        float: left;
        line-height: 40px;
        margin: auto;
    }
    .btn-height{
        min-height: 2.5vw;
    }
</style>