export const request_list_grid_sets = [
    {"dataField":"ord_id", "caption":"ID", "dataType":"number"},
    // {"dataField":"buttons", "caption":"Действия", "type":"buttons", "name":"edit"},
    {"dataField":"ord_num", "caption":"Номер заказа", "dataType":"string"},
    {"dataField":"ord_date", "caption":"Дата размещения", "dataType":"date","sortOrder":"asc"},
    {"dataField":"status", "caption":"Статус", "dataType":"string"},
    {"dataField":"cont_num", "caption":"№ контейнера", "dataType":"string"},
    {"dataField":"port_name", "caption":"Порт прибытия", "dataType":"string"},
    {"dataField":"gds_name", "caption":"Товар", "dataType":"string"},
    {"dataField":"shpr_name", "caption":"Отправитель", "dataType":"string"},
    {"dataField":"inv_num", "caption":"№ инвойса", "dataType":"string"},
    {"dataField":"whs_name", "caption":"Склад", "dataType":"string"},
    {"dataField":"cntt_name", "caption":"Тип контейнера", "dataType":"string"},
    {"dataField":"dlvt_code", "caption":"Условия поставки", "dataType":"string"},
    {"dataField":"place_name_from", "caption":"Место отправки", "dataType":"string"},
    {"dataField":"place_name_to", "caption":"Место доставки", "dataType":"string"},
    {"dataField":"line_name", "caption":"Линия", "dataType":"string"},
    {"dataField":"ready_date", "caption":"Дата готовности", "dataType":"date"},
    {"dataField":"loading_date", "caption":"Дата загрузки", "dataType":"date"},
    {"dataField":"sea_start_date", "caption":"Выход в море", "dataType":"date"},
    {"dataField":"waybill_num", "caption":"№ коносамента", "dataType":"string"},
//    {"dataField":"packing_date", "caption":"Packing", "dataType":"date"},
    {"dataField":"telex", "caption":"Релиз по MBL", "dataType":"string"},
    {"dataField":"sea_end_date", "caption":"Дата прихода в порт", "dataType":"date"},
//    {"dataField":"doc_rec_date", "caption":"ID", "dataType":"date"},
    {"dataField":"dcl_ready_date", "caption":"Дата подачи ДТ", "dataType":"date"},
    {"dataField":"dcl_num", "caption":"Номер ДТ", "dataType":"string"},
    {"dataField":"dcl_reg_date", "caption":"Дата выпуска ДТ", "dataType":"date"},
    {"dataField":"rw_start_date", "caption":"Дата постановки на жд", "dataType":"date"},
    {"dataField":"rw_end_date", "caption":"Дата прибытия по жд", "dataType":"date"},
    {"dataField":"unloading_date", "caption":"Дата выгрузки", "dataType":"date"},
    {"dataField":"return_container_date", "caption":"Дата возврата порожнего", "dataType":"string"},
    {"dataField":"location_place", "caption":"Местонахождение", "dataType":"string"},
    {"dataField":"vessel_name", "caption":"Наименование судна", "dataType":"string"},
    {"dataField":"distance", "caption":"Дистанция", "dataType":"number"},
    {"dataField":"cons_name", "caption":"Импортер", "dataType":"string"},
    {"dataField":"client_email", "caption":"Email клиента", "dataType":"string"},
//    {"dataField":"color", "caption":"color", "dataType":"string"},
//    {"dataField":"colors", "caption":"colors", "dataType":"string"},
    {"dataField":"is_vtt", "caption":"ВТТ", "dataType":"string"},
    {"dataField":"hbl_status_date", "caption":"Дата HBL", "dataType":"number"},
    // {"dataField":"hbl_status", "caption":"HBL", "dataType":"string"},
//    {"dataField":"plan_rw_put_date", "caption":"plan_rw_put_date", "dataType":"number"},
    {"dataField":"plan_rw_put_info", "caption":"Планируемый срок отправки по жд", "dataType":"string"},
    {"dataField":"actual_date", "caption":"Дата актуальности", "dataType":"date"},
//    {"dataField":"doc_ready_date", "caption":"Дата подачи ДТ", "dataType":"date"},
    {"dataField":"gds_cost", "caption":"Стоимость товара", "dataType":"number", "format":"#,##0.00"},
    {"dataField":"gds_gross_weight", "caption":"Вес товара", "dataType":"number", "format":"#,##0.00"},
    {"dataField":"gds_volume", "caption":"Объем", "dataType":"number", "format":"#,##0.00"},
    {"dataField":"broker_name", "caption":"Брокер", "dataType":"string"},
    {"dataField":"agent_name", "caption":"Агент", "dataType":"string"},
    {"dataField":"whs_addr", "caption":"Адрес склада", "dataType":"string"},
    {"dataField":"vehicle_name", "caption":"Автоперевозчик", "dataType":"string"},
    {"dataField":"place_quantity", "caption":"Количество мест", "dataType":"number"},
    {"dataField":"amount_sum", "caption":"Ставка клиенту", "dataType":"number", "format":"#,##0.00"},
    {"dataField":"pay_sum", "caption":"Оплачено", "dataType":"number", "format":"#,##0.00"},
    {"dataField":"dolg", "caption":"Задолженность", "dataType":"number", "format":"#,##0.00"},
    {"dataField":"day_on_road", "caption":"Дней в пути", "dataType":"number"},
    {"dataField":"plan_sea_start_date", "caption":"Плановая дата выхода в море", "dataType":"date"},
    {"dataField":"plan_sea_end_date", "caption":"Плановая дата прихода в порт", "dataType":"date"},
    {"dataField":"cvx_in_date", "caption":"Дата ДО", "dataType":"date"},
    {"dataField":"cvx_out_date", "caption":"Дата закрытия по складу", "dataType":"date"},
    {"dataField":"tk", "caption":"Трамис-коносамент", "dataType":"string"},
    {"dataField":"is_arreas", "caption":"Просрочен", "dataType":"string"},
    {"dataField":"plan_unloading_date", "caption":"Плановая дата прибытия на склад", "dataType":"date"},
    {"dataField":"delivery_station", "caption":"Станция прибытия", "dataType":"string"},
    {"dataField":"station_unloading_date_expd", "caption":"Дата выгрузки на станции (экспедитор)", "dataType":"date"},
    {"dataField":"ship_call_date", "caption":"Дата постановки под выгрузку", "dataType":"date"},
    {"dataField":"is_on_raid", "caption":"Стоит на рейде", "dataType":"string"},
    {"dataField":"on_raid_date", "caption":"Дата постановки на рейд", "dataType":"date"},
    {"dataField":"is_transshipment", "caption":"Выгружен в порту трансшипмента", "dataType":"string"},
    {"dataField":"transshipment_date", "caption":"Дата выгрузки в порту трансшипмента", "dataType":"date"},
    {"dataField":"platform_no", "caption":"Платформа", "dataType":"string"},
    {"dataField":"platform_common_type", "caption":"Тип платформы", "dataType":"string"},
    {"dataField":"hbl_status_name", "caption":"Статус HBL", "dataType":"string"},
    // { "dataField": 'customAction', "caption": 'Действие', "cellTemplate": 'customActionTemplate', "type":"buttons"},
  
    // {"dataField":"state_id", "caption":"ID статуса", "dataType":"number"},
    //    {"dataField":"station_unloading_date_expd", "caption":"Дата выгрузки на станции экспедитор", "dataType":"date"},
]

export const history_grid_sets = [
    {"dataField":"processedat", "caption":"Дата", "dataType":"date"},
//    {"dataField":"ended_status", "caption":"Код решения", "dataType":"numeric"},
//    {"dataField":"messagetype", "caption":"Тип сообщения", "dataType":"string"},
    {"dataField":"statustext", "caption":"Статус", "dataType":"string"},
    {"dataField":"substatustext", "caption":"Сообщение", "dataType":"string"},
    {"dataField":"dcl_reg_num", "caption":"Номер декларации", "dataType":"string"},
]

export const request_comments_grid_sets = [
    {"dataField":"crdate", "caption":"Дата комментария", "dataType":"datetime", "format":"dd.MM.yyyy HH:mm:ss"},
    {"dataField":"uuser", "caption":"Отправитель", "dataType":"string"},
    {"dataField":"to_user_name", "caption":"Получатель", "dataType":"string"},
    {"dataField":"description", "caption":"Текст", "dataType":"string"}
];

export const request_history_grid_sets = [
    {"dataField":"processedat", "caption":"Дата события", "dataType":"date"},
    {"dataField":"statustext", "caption":"Статус", "dataType":"string"},
    {"dataField":"substatustext", "caption":"Пояснения", "dataType":"string"},
    {"dataField":"ended_status", "caption":"Код статуса", "dataType":"number"}
];
