import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('DxDataGrid',{ref:_vm.dataGridRefName,attrs:{"width":"100%","id":"mt-order-history","columns":_vm.columns,"selection":{ mode: 'single' },"data-source":_vm.comments,"allow-column-reordering":true,"show-borders":true,"allow-column-resizing":true,"column-resizing-mode":_vm.currentMode,"column-min-width":50,"column-auto-width":true,"hoverStateEnabled":true,"word-wrap-enabled":true,"row-alternation-enabled":_vm.rowAlternationEnabled,"no-data-text":_vm.nodatatext},on:{"row-dbl-click":_vm.onrowdblclick,"row-click":_vm.onrowdblclick}},[_c(VDialog,{staticClass:"mt-editor-form",attrs:{"persistent":"","transition":"dialog-bottom-transition","width":"60%","height":"60%"},model:{value:(_vm.showMessageForm),callback:function ($$v) {_vm.showMessageForm=$$v},expression:"showMessageForm"}},[_c('MessageForm',{attrs:{"commentType":_vm.commentType,"document":_vm.document,"is_new":_vm.is_new,"ord_id":this.ord_id},on:{"close":function($event){_vm.showMessageForm = false, _vm.$emit('updateComments')}}})],1),_c(VDialog,{staticClass:"mt-editor-form",attrs:{"persistent":"","transition":"dialog-bottom-transition","width":"45%","height":"60%"},on:{"click:outside":function($event){_vm.showCommentText = false}},model:{value:(_vm.showCommentText),callback:function ($$v) {_vm.showCommentText=$$v},expression:"showCommentText"}},[_c(VCard,[_c('div',{staticClass:"comment-dialog-text"},[_vm._v(" "+_vm._s(_vm.document.description)+" ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"var(--main-bg-color)","text":""},on:{"click":function($event){_vm.showCommentText = false}}},[_vm._v(" Закрыть ")])],1)],1)],1),_c('DxScrolling',{attrs:{"mode":"infinite"}}),_c('DxStateStoring',{attrs:{"enabled":true,"type":"localStorage","storage-key":"dcl-request-comments"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"request-title"},[_c('div',{staticClass:"comments-action"},[_c('div',{staticClass:"btn-left"}),_c('div',{staticClass:"btn-right"})])])
}]

export { render, staticRenderFns }