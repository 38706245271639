import { render, staticRenderFns } from "./CompanyInfoCard.vue?vue&type=template&id=61dc6ac6&scoped=true"
import script from "./CompanyInfoCard.vue?vue&type=script&lang=js"
export * from "./CompanyInfoCard.vue?vue&type=script&lang=js"
import style0 from "./CompanyInfoCard.vue?vue&type=style&index=0&id=61dc6ac6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61dc6ac6",
  null
  
)

export default component.exports