<script>
import RequestTimeline from "./RequestTimeline.vue";
export default {
  name: "mt-order-control.vue",
  props: {
    order: {
      type: Object
    }
  },
  
  components: {
    RequestTimeline
  }
}
</script>

<template>
  <div class="mt-order-data">
    <p class="headline mb-1 btm-brdr-right">Заказ:&nbsp;{{order.ord_num}}</p>
    <div class="mt-order-main-data">
      <div class="mt-order-main-data-column">
        <v-list-item-content>
          <div class="mt-order-data-block">
            <v-card-title class="btm-brdr-right">Заказ</v-card-title>
            <v-card-text class="text--primary">
              <div class="p-left-4 p-mrgn-left-4">Инвойс: {{order.inv_num}}</div>
              <div class="p-left-4 p-mrgn-left-4">Поставщик: {{order.shpr_name}}</div>
              <div class="p-left-4 p-mrgn-left-4">Получатель: {{order.cons_name}}</div>
            </v-card-text>
          </div>
          <div class="mt-order-data-block br-margin">
            <v-card-title class="btm-brdr-right">Товар</v-card-title>
            <v-card-text class="text--primary">
              <div class="p-left-4 p-mrgn-left-4">Наименование: {{order.gds_name}}</div>
              <div class="p-left-4 p-mrgn-left-4">Вес: {{order.gds_gross_weight}}</div>
              <div class="p-left-4 p-mrgn-left-4">Стоимость: {{order.gds_cost}}</div>
              <div class="p-left-4 p-mrgn-left-4">Объем: {{order.gds_volume}}</div>
              <div class="p-left-4 p-mrgn-left-4">Количество мест: {{order.place_quantity}}</div>
            </v-card-text>
          </div>
          <div class="mt-order-data-block">
            <v-card-title class="btm-brdr-right">ТО</v-card-title>
            <v-card-text class="text--primary">
              <div class="p-left-4 p-mrgn-left-4">ДТ: {{order.dcl_num}}</div>
              <div class="p-left-4 p-mrgn-left-4">Брокер: {{order.broker_name}}</div>
            </v-card-text>
          </div>
        </v-list-item-content>
      </div>
      <div class="mt-order-main-data-column">
        <v-list-item-content>
          <div class="mt-order-data-block">
            <v-card-title class="btm-brdr-right">Поставка</v-card-title>
            <v-card-text class="text--primary">
              <div class="p-left-4 p-mrgn-left-4">Агент: {{order.agent_name}}</div>
              <div class="p-left-4 p-mrgn-left-4">Условия поставки: {{order.dlvt_code}}</div>
              <div class="p-left-4 p-mrgn-left-4">Маршрут: {{order.place_name_from}} -> {{order.port_name}} -> {{order.place_name_to}}</div>
              <div class="p-left-4 p-mrgn-left-4">Линия: {{order.line_name}}</div>
              <div class="p-left-4 p-mrgn-left-4">Коносамент: {{order.waybill_num}}</div>
              <div class="p-left-4 p-mrgn-left-4">Автоперевозчик: {{order.vehicle_name}}</div>
              <div class="p-left-4 p-mrgn-left-4">Номер контейнера: {{order.cont_num}}</div>
            </v-card-text>
          </div>
          <div class="mt-order-data-block">
            <v-card-title class="btm-brdr-right">Склад</v-card-title>
            <v-card-text class="text--primary">
              <div class="p-left-4 p-mrgn-left-4">Наименование: {{order.whs_name}}</div>
              <div class="p-left-4 p-mrgn-left-4">Адрес: {{order.whs_addr}}</div>
            </v-card-text>
          </div>
          <div class="mt-order-data-block">
            <v-card-title class="btm-brdr-right">Дислокация</v-card-title>
            <v-card-text class="text--primary">
              <div class="p-left-4 p-mrgn-left-4">Место нахождения: {{order.location_place}}</div>
              <div class="p-left-4 p-mrgn-left-4">Осталось, км: {{order.distance}}</div>
            </v-card-text>
          </div>
        </v-list-item-content>
      </div>
    </div>
    <RequestTimeline class="ml-1" :document="order"/>
  </div>
</template>

<style scoped>
.btm-brdr-right{
  border-bottom: 0.05208vw solid #008cd0;
  text-align: right;
  margin-right: 2%;
  margin-left: 0%;
}
.mt-order-data{
  display: flex;
  flex-direction: column;
}
.mt-order-main-data{
  display: flex;
  flex-direction: row;
}
.mt-order-data-block{
  background-color: #ffffff;
}
.p-left-4{
  line-height: 1.30vw;
  text-align: left;
  margin-right: 4%;
  font-size: 14px;
  text-overflow: ellipsis;
}
.p-mrgn-left-4{
  margin-left: 0%;
}
.mt-order-main-data-column{
  width: 50%;
  margin-left: 2%;
  margin-right: 2%;
}
.br-margin{
  /*        margin-bottom: 4.35%; */
}
</style>
